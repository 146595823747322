// React and PropTypes
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// MUI Components
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

// MUI Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// Theme
import theme from '../../theme';



/**
 * Renders a page limit selector component with customizable options and callbacks.
 *
 * @param {Array} options - The array of page limit options. Default is ["20", "40", "60", "100", "200", "400", "800", "1000"].
 * @param {number} selectedPageSize - The currently selected page size.
 * @param {function} onPageSizeChange - The callback function to handle page size changes.
 * @param {string} buttonVariant - The variant of the button. Default is "contained", can be "outlined" or "text" supported by MUI.
 * @param {string} buttonColor - The color of the button. Default is theme.palette.primary.main.
 * @param {string} buttonTextColor - The text color of the button. Default is theme.palette.secondary.darklight.
 * @param {string} hoverColor - The hover color of the button. Default is theme.palette.primary.light.
 * @param {string} borderColor - The border color of the button. Default is theme.palette.primary.dark.
 * @param {string} minHeight - The minimum height of the button group. Default is "36px".
 * @param {string} width - The width of the button. Default is "max-content".
 * @returns {JSX.Element} The rendered page limit selector component.
 */
const PageLimitSelector = ({
    options = ["20", "40", "60", "100", "200", "400", "800", "1000"],
    selectedPageSize, // Mandatory Prop
    onPageSizeChange, // Mandatory Prop
    buttonVariant = "contained",
    buttonColor = theme.palette.primary.main,
    buttonTextColor = theme.palette.secondary.darklight,
    hoverColor = theme.palette.primary.light,
    borderColor = theme.palette.primary.dark,
    minHeight = '36px',
    width = 'max-content',
    disablePortal,
}) => {    
    // State to control the visibility of the dropdown menu
    const [open, setOpen] = useState(false);
    //state to control the Arrowicon
    const [isUpward, setIsUpward] = useState(false);

    // Reference to the button that opens the dropdown menu
    // This anchorRef is used for determining the position of the dropdown menu
    const anchorRef = useRef(null);

    /**
     * Handles the page size change event.
     * @param {Event} event - The click event.
     * @param {number} newPageSize - The new page size to be set.
     */
    const handleMenuItemClick = (event, newPageSize) => {
        setOpen(false);
        if (onPageSizeChange) {
            // Call the onPageSizeChange callback with the new page size
            onPageSizeChange(newPageSize);
        }
        setIsUpward(false)
    };
    /**
     * Toggles the visibility of the dropdown menu.
     */
    const handleToggle = () => {
        // Toggle dropdown
        setOpen((prev) => !prev);

        // Check if the dropdown should display upwards
        if (anchorRef.current) {
            const rect = anchorRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            setIsUpward(rect.bottom + 200 > viewportHeight);
        }
    };

    /**
     * Closes the dropdown menu when clicking outside of it.
     * @param {Event} event - The click event.
     */
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        setIsUpward(false)
    };

    return (
        <>
            <ButtonGroup
                variant={buttonVariant}
                ref={anchorRef}
                aria-label="Button group for selecting page size"
                sx={{
                    minHeight: minHeight,
                    '& .MuiButton-root': {
                        borderRadius: '4px',
                        fontFamily: 'inherit',
                        '&:hover': {
                            backgroundColor: hoverColor,
                        },
                    },
                    '& .MuiButton-contained': {
                        width: width,
                        backgroundColor: buttonColor,
                        px:"10px",
                        color: buttonTextColor,
                        '&:not(:last-child)': {
                            borderRight: `1px solid ${borderColor}`,
                        },
                    },
                }}
            >
                <Button >{`Page Limit (${selectedPageSize ?? ""})`}</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select page size"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    {isUpward ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Button>
            </ButtonGroup>
            <Popper
                style={{ zIndex: 11 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal={disablePortal}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper elevation={4}>
                            <div
                                style={{
                                    padding: '5px',
                                    minWidth: '150px',
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {options.map((option) => (
                                            <MenuItem
                                                key={option}
                                                selected={option === selectedPageSize}
                                                onClick={(event) => handleMenuItemClick(event, Number(option))}
                                                sx={{
                                                    display: 'block !important',
                                                    padding: '8px 16px !important',
                                                    marginBottom: '4px !important'
                                                }}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </div>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

PageLimitSelector.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string),
    selectedPageSize: PropTypes.number.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    buttonVariant: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
    hoverColor: PropTypes.string,
    borderColor: PropTypes.string,
    minHeight: PropTypes.string,
    width: PropTypes.string
};

export default PageLimitSelector;
