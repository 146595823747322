// React and PropTypes
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// MUI Components
import { Stack, IconButton, Typography } from '@mui/material';

// MUI Icons
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Custom Components
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import Table from '../../commons/Table/Table';
import PageLimitSelector from '../../commons/PageLimitSelector';
import BatchViewStatusFilter from './BatchViewStatusFilter';
import Notify from '../../commons/notify';
import AsyncTypeInput from '../../commons/input/AsyncTypeHead/AsyncTypeInput';
import Label from '../../commons/Label';

// Contexts
import LoadingContext from "../../../container/loadingContext";

// Services
import { getBatchLevelClaims, AddRemoveSelectedTab, GetSelectedTabs } from './service';
import service from '../service';

// Utilities
import { BATCH_STATUS, DEFAULT_PAGING_SIZE, MAX_OPEN_TABS_CLAIMS, ROUTE_SEARCH_CLAIMS } from '../../../utilities/staticConfigs';
import { BatchLevelClaimsTable, TableBodyData } from './batchViewTable';
import { checkPermission, commonTableBody, openCenteredPopupWindow } from '../../../utilities/commonUtilities';
import { permission_key_values_claim } from '../../../utilities/permissions';
import { getStorage } from '../../../utilities/browserStorage';

// Theme
import theme from '../../../theme';

// Locale
import i18n from '../../../utilities/i18n';



/**
 * Renders a component with batch processing details.
 *
 * @param {number | string} batchID - The ID of the batch processed.
 * @param {Function} setBatchId - The state update function for setting the batch ID.
 * @param {Array} selectedClaim - The array of selected claim IDs in search field.
 * @returns {JSX.Element} The rendered JSX component.
 */
function BatchView({
    batchId,
    setBatchId,
    selectedClaimInLogs
}) {

    const setShowLoading = useContext(LoadingContext);

    // Batch status options with id and label
    const batchStatusOptions = useMemo(() => BATCH_STATUS, []);

    // Parent Modal state
    const [showModal, setShowModal] = useState(false);

    // Row table data response - storing to use for claim routing and to get the success and error counts
    const [batchLevelClaimData, setBatchLevelClaimData] = useState(null);

    // Current status filter selection
    const [statusFilterValue, setStatusFilterValue] = useState(0);

    // Claim ID input field selection
    const [selectedClaim, setSelectedClaim] = useState(Array.isArray(selectedClaimInLogs) ? selectedClaimInLogs : []);
    // Claims dropdown options list
    const [claimsList, setClaimsList] = useState([]);

    // Notify Configs
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    /****************************** Pagination starts ******************************/
    const [pageLimit, setPageLimit] = useState(DEFAULT_PAGING_SIZE);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    /**
     * @description User event handler on page change
     * @param {number} newPage - The new page number to navigate to
     */
    const handlePageChange = (newPage) => {
        // The offset is calculated by multiplying the new page number by the page limit
        // When page 1 is called, the offset is 0, When page 2 is called, the offset is (2 - 1) multiplied by the limit.
        // Logic of setting the state on current page, current offset and page limit is handled in the fetchBatchLevelClaims function
        const newOffset = (newPage - 1) * pageLimit;
        fetchBatchLevelClaims(newOffset);
    };

    /**
     * @description Util function to resets the pagination to the initial state
     */
    const resetPaginationToInitialState = () => {
        setOffset(0);
        setCurrentPage(1);
    }
    /****************************** Pagination ends ******************************/


    /**
     * @description Master API Functionality to get the Table Data
     * @param {*} reqOffset Mandatory parameter
     * @param {*} reqStatus Optional parameter 
     * @param {*} newPageLimit Optional parameter 
     * @param {*} reqClaimPk Optional parameter 
     */
    const fetchBatchLevelClaims = async (reqOffset, reqStatus, newPageLimit, reqClaimPk) => {
        // Incase batch id not available evoke return immediately
        if (!batchId) {
            setShowModal(false);
            return;
        }

        // Make sure the modal is in open state to render the data
        if (!showModal) {
            setShowModal(true);
        }

        setShowLoading(true);

        try {
            // loading the practice and validating the same
            const practiceId = localStorage.getItem('practice');
            if (!practiceId) {
                throw new Error("Practice ID not found in local storage.");
            }

            const newOffset = reqOffset ?? offset;

            // Since 0 is a falsy value in JavaScript, and in this case, it's a valid status code, 
            // the standard ternary operator (reqStatus ? reqStatus : statusFilterValue) would not work when reqStatus id is 0.
            // The logic below ensures that 0 is treated as a valid value, along with 3 and 5.
            const status = [...batchStatusOptions.map(status => status.id)].includes(reqStatus) ? reqStatus : statusFilterValue;

            let claimPk = reqClaimPk ?? selectedClaim[0]?.id ?? "";
            // To handle when user clears the claim ID input field
            if (reqClaimPk === "") {
                claimPk = null
            }

            // Api execution to get the table data of batch level claims
            const response = await getBatchLevelClaims(
                practiceId,
                batchId,
                status,
                newOffset,
                newPageLimit ?? pageLimit,
                claimPk
            );

            if (response?.data?.results && Array.isArray(response.data.results)) {
                setBatchLevelClaimData(response.data);
                const rowArray = commonTableBody(
                    response.data.results,
                    BatchLevelClaimsTable.tableBodyData[0]
                );

                BatchLevelClaimsTable.tableBodyData = rowArray;
                if (response.data?.total_claims_processed)
                if (newPageLimit)
                    setPageLimit(newPageLimit);

                if (status != statusFilterValue)
                    setStatusFilterValue(status);

                if (reqOffset != offset)
                    setOffset(reqOffset)

                // Since batch status is stored in the influx db, the pagination is not able to handle as for the rest apis in the system.
                // Hence added the below logic to handle the pagination using the offset
                // When page is in 1, the offset should be 0
                // And as pagination increases the offset is calculated by (page - 1) * pageLimit
                // Lastly, the current page is calculated by dividing the current offset by current pageLimit and adding 1
                setCurrentPage(Math.ceil(newOffset / pageLimit) + 1);

            } else {
                throw new Error("Invalid response: Batch level claim data is missing or malformed.");
            }
        } catch (error) {
            // Empty the current table state when any error occurs and rest the pagination
            BatchLevelClaimsTable.tableBodyData = TableBodyData;
            resetPaginationToInitialState();
            showNotifyWindow('show', 'error', i18n.t("errorMessages.Un_expected_error"));
            console.error(error);
        } finally {
            setShowLoading(false);
        }
    };


    /**
     * Triggers the API call to fetch batch-level claims- 
     * whenever the `batchId` prop is received or updated from the parent component.
     */
    useEffect(() => {
        fetchBatchLevelClaims(offset);
    }, [batchId]);


    /**
     * @description Triggers when the page limit is changed from the page limit selector component
     * @description Once the page limit is changed, the API call is triggered to fetch the claims based on the new page limit
     * @param {number} newPageSize - The new page limit to fetch the claims
     */
    const handlePageSizeChange = (newPageSize) => {
        fetchBatchLevelClaims(0, null, newPageSize);
    };


    /**
     * @description Triggers when claim status is changed from the status filter component
     * @description Once the status is changed, the API call is triggered to fetch the claims based on the new status
     * @param {number} newStatus - The new status id to filter by
     */
    const handleFilterByStatus = (newStatus) => {
        fetchBatchLevelClaims(0, newStatus);
    };


    /**
     * @description Triggers when the claim ID is clicked from the table
     * @description Further validates the permission and evokes the onClaimIDClick function
     * @param {number} id - The ID of the claim to route to
     */
    const routeToClaimPage = (id) => {
        let item = batchLevelClaimData?.results?.find(obj => obj.id == id);
        if (checkPermission(permission_key_values_claim.claims_search_claims_view)) {
            onClaimIDClick(item.claim_pk, item.claim_id);
        }
    }


    /**
     * @description Executes the claim page routing functionality
     * @param {*} pk - claims's primary key  as number
     * @param {*} claimId - custom claimID as string
     */
    const onClaimIDClick = async (pk, claimId) => {
        let openedPKs = false;

        setShowLoading(true);

        try {
            // Get the opened claim tabs list of the session user from the database
            const response = await GetSelectedTabs('claims', getStorage("practice"));
            openedPKs = response?.data?.opened_tab_pks ? response.data.opened_tab_pks : [];
            //  remove last10 and search from the opened tabs list
            let allowedPk = ['search', 'last10'];
            allowedPk.forEach((item) => {
                let index = openedPKs.indexOf(item);
                if (index !== -1) {
                    openedPKs.splice(index, 1);
                }
            });

            // If user does not have tabs open more than the allowed limit, then only allow to open this new claim tab
            if (openedPKs && Array.isArray(openedPKs) && openedPKs.length >= MAX_OPEN_TABS_CLAIMS && !openedPKs.includes(pk)) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.max_claim_tabs'));
            } else {
                // Add the new claim tab to the opened tabs list by calling the respective API
                let item = { pk: Number(pk), claim_id: claimId, type: 'claims', action: 'add', practice_pk: getStorage("practice") };
                const response = await AddRemoveSelectedTab(item);
                if (response?.data?.code === 200) {
                    // Define the URL for the claim module
                    const claimUrl = `${window.location.origin}${ROUTE_SEARCH_CLAIMS}`;

                    // Callback function to execute when user closes the popup window.
                    // This callback will handle closing the claim tab from the opened tabs list in the database
                    const onClaimPopupTabClose = async () => {
                        try {
                            // Update the tab status to 'close'
                            await AddRemoveSelectedTab({ ...item, action: 'remove' });
                        } catch (error) {
                            console.error('Error while closing the claim popup tab:', error);
                        }
                    };

                    // Open the claim module in a new centered popup window
                    openCenteredPopupWindow(claimUrl, 'claimPopup', null, null, onClaimPopupTabClose);
                } else {
                    // Throw an error if the response code is not 200
                    throw new Error(i18n.t("errorMessages.Un_expected_error"));
                }
            }
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t("errorMessages.Un_expected_error"));
            console.error('Error fetching selected tabs:', error);
        } finally {
            setShowLoading(false);
        }
    };


    /**
     * @description Fetches the claims list based on search query.
     * @param {string} query - The search query.
     */
    function getClaimsList(query) {
        query = query + '&filter=1';
        const result = service.ListClaimsDropDown(
            query,
            getStorage("practice")
        );

        result.then((response) => {
            setClaimsList(response.data);
        });
    }


    /**
     * @description Handles the change in the claim ID input field.
     * @param {Array} e - The selected claim ID.
     */
    function onHandleClaimIDChange(e) {
        // Validate if claim pk exists
        if (Array.isArray(e) && e[0]?.id) {
            setSelectedClaim(e)
            fetchBatchLevelClaims(0, null, null, e[0].id);
        } else {
            setSelectedClaim([])
            fetchBatchLevelClaims(0, null, null, "");
        }
    }

    /**
     * @description Calculates the total number of pages based on the current filter status
     * @returns {number} The total number of pages
     */
    const calculateTotalPages = () => {
        let totalItems;
        switch (statusFilterValue) {
            case 5: // 5 is the status code for error
                totalItems = batchLevelClaimData?.failed_claim_count || 0;
                break;
            case 3: // 3 is the status code for success
                totalItems = batchLevelClaimData?.success_claim_count || 0;
                break;
            case 0: // 0 is the status code for success (all claims)
                totalItems = batchLevelClaimData?.total_claims_processed || 0;
        }
        return Math.max(1, Math.ceil(totalItems / pageLimit));
    };

    return (
        <div>

            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />

            <CustomizedDialogs
                type="info"
                header={`Claims in the Batch ${batchId}`}
                showModal={showModal}
                setShowModalWindow={(newState) => {
                    // When the dialog is closed, reset the batch view components to the initial state
                    if (newState == false) {
                        setShowModal(false);
                        setBatchId(null);
                        setSelectedClaim([]);
                        setClaimsList([]);
                        BatchLevelClaimsTable.tableBodyData = TableBodyData;
                        resetPaginationToInitialState();
                        setStatusFilterValue(0);
                        setBatchLevelClaimData(null);
                        setShowLoading(false);
                    }
                }}
            >
                <Stack direction="row" spacing={2} justifyContent="space-between" marginBottom={2} alignItems="flex-end">

                    {/* Total, Success, Failed counts */}
                    <Stack direction="row" spacing={2} alignItems="flex-end">
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                            Total ({batchLevelClaimData?.total_claims_processed || ""})
                        </Typography>
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                            <DoneIcon sx={{ color: theme.palette.success.main, fontWeight: 500, mr: 1 }} />
                            Success ({batchLevelClaimData?.success_claim_count || ""})
                        </Typography>
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                            <ErrorOutlineIcon sx={{ color: theme.palette.error.main, fontWeight: 500, mr: 1 }} />
                            Failed ({batchLevelClaimData?.failed_claim_count || ""})
                        </Typography>
                    </Stack>


                    <Stack direction="row" spacing={2} alignItems="flex-end"> 
                        <div>
                            <Label label="Search by Claim ID" />
                            <AsyncTypeInput
                                id="searchByClaimIdFromBatchView"
                                labelKey="name"
                                minLength={0}
                                options={claimsList}
                                onSearch={getClaimsList}
                                name="selectClaim"
                                onValueChange={onHandleClaimIDChange}
                                selected={selectedClaim}
                                placeholder={i18n.t(
                                    "payments.post_payments.insurancePayment.searchByClaimId"
                                )}
                            />
                        </div>

                        <BatchViewStatusFilter
                            selectedStatusItem={statusFilterValue}
                            setSelectedStatusItem={handleFilterByStatus}
                        />

                        <PageLimitSelector
                            selectedPageSize={pageLimit}
                            onPageSizeChange={handlePageSizeChange}
                            disablePortal={true}
                        />
                    </Stack>
                </Stack>


                <Table
                    tableObject={BatchLevelClaimsTable}
                    onLinkClick={routeToClaimPage}
                />


                {/* Pagination */}
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
                    <IconButton
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={offset === 0}
                    >
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <Typography variant="body2">
                        {currentPage} / {calculateTotalPages()}
                    </Typography>
                    <IconButton
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage >= calculateTotalPages()}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Stack>

            </CustomizedDialogs>
        </div>
    )
}

BatchView.propTypes = {
    batchId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    setBatchId: PropTypes.func.isRequired,
    selectedClaimInLogs: PropTypes.array
};

export default BatchView;