import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../../components/patientManagement/patients/service";
import { getStorage } from "../../utilities/browserStorage";
import { DEFAULT_COUNTRY } from "../../utilities/staticConfigs";

/**
 * reducer initial state value
 */
const initialState = {
  patientInfoData: [],
  patientAddDetails: {
    locked_by_name: "",
    locked: false,
    patient_editable: true,
    patient_inactive: false,
    profile_locked: "",
    patient: "",
    custom_patient_id: "",
    last_name: "",
    first_name: "",
    middle_name: "",
    suffix: "",
    gender: "",
    date_of_birth: "",
    date_of_death: "",
    patient_type: "",
    ssn: "",
    other_id: "",
    active: true,
    detail_ids: {
      patient_info: "",
      billing_info: "",
    },
  },
  patientInfoDetails: {
    id: "",
    active: true,
    authorized_contact: 1,
    cell_phone: "",
    email: "",
    emergency_as_guarantor: true,
    emergency_cell_phone: "",
    emergency_email: "",
    emergency_first_name: null,
    emergency_home_phone: null,
    emergency_last_name: null,
    emergency_location: null,
    emergency_location_data: {
      city: "",
      country: "",
      state: "",
      street1: "",
      street2: "",
      zip_code: "",
    },
    emergency_middle_name: null,
    emergency_relationship: "",
    emergency_relationship_name: "",
    emergency_suffix: null,
    emergency_work_phone: "",
    ethnicity: 4,
    facility_location: null,
    guarantor_address_as_patient: false,
    guarantor_cell_phone: "",
    guarantor_email: null,
    guarantor_first_name: null,
    guarantor_home_phone: null,
    guarantor_last_name: null,
    guarantor_location: null,
    guarantor_location_data: {
      city: "",
      country: "",
      state: "",
      street1: "",
      street2: "",
      zip_code: "",
    },
    guarantor_middle_name: null,
    guarantor_relationship: "",
    guarantor_relationship_name: "",
    guarantor_suffix: null,
    guarantor_work_phone: "",
    home_phone: "",
    location: "",
    location_data: {
      city: "",
      country: DEFAULT_COUNTRY,
      state: "",
      street1: "",
      street2: "",
      zip_code: "",
    },
    patient: "",
    patient_status: true,
    practice_location: "",
    preferred_language: "",
    race: 6,
    referring_provider: null,
    referring_provider_group: "",
    rendering_provider: null,
    work_phone: "",
    service_location_value: "",
    service_location: {},
  },
  pks: [],
  renderingProviderList: [],
  patientFormWarnings: {
    model: [
      {
        "Last Name": "",
        "First Name": "",
        Gender: "",
        DOB: "",
        "Patient Type": "",
      },
      {
        tabName: "Patient Info",
        Street1: "",
        City: "not-set",
        State: "not-set",
        Zip: "",
        "Invalid Zip": "true",
        "Rendering Provider": "not-set",
      },
      {
        tabName: "Insurance Info",
        "Insurance Details": "",
      },
    ]
  },
  patientFormWarningStatus: null,
  dropDownListData: {
    patientTypesList: []
  },
  loading: false,
  error: "",
};

/**
 * api call for get selected patient Details
 * @param {*} payload
 */
export const getPatientAddDetails = createAsyncThunk(
  "patient/patientAddVariables",
  async (payload) => {
    /**
     * api call to get patient basic info detais
     * @param {*} pk
     */
    const resultPatientAdd = await service.getPatientData(payload.pk);
    /**
     * api call to get patient all info details
     * @param {*} pk
     */
    const resultPatientInfo = await getPatientotherDetails(
      resultPatientAdd.data.detail_ids.patient_info
    );

    return{
      patientAddVariables: resultPatientAdd.data,
      patientInfoVariables: resultPatientInfo.data,
      pk: payload.pk,
      infopk: resultPatientAdd.data.detail_ids.patient_info,
    };
  }
);

/**
 * api call for get selected patient Details
 * @param {*} pk
 */
export const getPatientotherDetails = async (PK) => {
  /**
   * calling api for getting all patient info data
   */
  const res = await service.getPatientInfoData(PK);
  return { data: res.data };
};

export const getRenderingProviders = createAsyncThunk(
  "patient/renderingproviders",
  async (pk) => {
  const praticePK = getStorage("practice");
  const result = await service.ListRenderingProviders(20, 0, praticePK, pk);
  return result.data;
});

export const getPatientInfoDetails = createAsyncThunk(
  "patient/patientInfoAdd",
  async (payload) => {
    /**
     * calling api for getting all patient info data
     */
    const res = await service.getPatientInfoData(payload.pk);
    return { data: res.data };
  }
);

//create slice for storing patient data
export const patientInfoSlice = createSlice({
  name: "patientInfo",
  initialState,
  reducers: {
    /**
     * getpatient data according to node value
     * @param {*} state
     * @param {*} actions
     */
    getpatientInfo: (state, actions) => {
      if (state.patientInfoData.length > 0) {
        state.loading=true;
        let data = state.patientInfoData.filter(
          (item) => item.patientPK === actions.payload.pk
        );
        switch (actions.payload.node) {
          case "patientAddVariables":
            state.patientAddDetails =
              data.length > 0 ? data[0].patientAddVariables : {};
              state.patientInfoDetails =
              data.length > 0 ? data[0].patientInfoVariables : {};
          break;
          case "patientInfoDetails":
            state.patientInfoDetails =
              data.length > 0 ? data[0].patientInfoVariables : {};
            break;
          default:
            break;
        }
        state.loading=false;
      }
    },
    /**
     * updating patientInfoData inside redux state
     * @param {*} state
     * @param {*} actions
     */
    updatePatientInfo: (state, actions) => {
      let index = state.patientInfoData.findIndex(
        (item) => item.patientPK === actions.payload.patientPK
      );
      let data = {
        ...state.patientInfoData[index],
        patientAddVariables: {
          ...state.patientInfoData[index].patientAddVariables,
          ...actions.payload.patientAddVariables,
        },
      };
      state.patientInfoData[index] = data;
    },

    updatePatientOtherInfo: (state, actions) => {
      let index = state.patientInfoData.findIndex(
        (item) => item.patientPK === actions.payload.patientPK
      );
      let data = {
        ...state.patientInfoData[index],
        ...actions.payload,
      };
      state.patientInfoData[index] = data;
    },

    activatePatient: (state, actions) => {
      let index = state.patientInfoData.findIndex(
        (item) => item.patientPK === actions.payload.patientPK
      );
      let data = {
        ...state.patientInfoData[index],
        patientAddVariables: {
          ...state.patientInfoData[index].patientAddVariables,
          active: true
        },
        patientInfoVariables: {
          ...state.patientInfoData[index].patientInfoVariables,
          active: false
        }
      };
      state.patientInfoData[index] = data;
    },

    resetPatientDataOnTabClose: (state, actions) => {
      // Find the index of the closing patient id and remove it from state
      const index = state.patientInfoData.findIndex(
        (item) => item.patientPK === actions.payload.pk
      );


      if (index !== -1) {
        const updatedPatientInfoData = [
          ...state.patientInfoData.slice(0, index),
          ...state.patientInfoData.slice(index + 1)
        ];

        const updatedPKs = state.pks.filter((item) => item !== actions.payload.pk);

        // Check if the current patientAddDetails id matches the closing patient id
        const patientAddDetails =
          state.patientAddDetails.id === actions.payload.pk
            ? initialState.patientAddDetails
            : state.patientAddDetails;

        // Check if the current patientInfoDetails patient matches the closing patient id
        const patientInfoDetails =
          state.patientInfoDetails.patient === actions.payload.pk
            ? initialState.patientInfoDetails
            : state.patientInfoDetails;
        
        // Cloning the Object to a new one to remove prototypes
        const patientFormWarnings = {};
        for (const [key, value] of Object.entries(state.patientFormWarnings)) {
          patientFormWarnings[key] = JSON.parse(JSON.stringify(value));
        }
        delete patientFormWarnings[actions.payload.pk];

        return {
          ...state,
          patientInfoData: updatedPatientInfoData,
          pks: updatedPKs,
          patientAddDetails,
          patientInfoDetails,
          patientFormWarnings
        };
      }

      return state; // If index is -1, item not found, return the original state
    },

    updatePatientDropdownData: (state, actions) => {
      const { dropDownListDataKey, dropDownListDataValue } = actions.payload;
      return {
        ...state,
        dropDownListData: {
          ...state.dropDownListData,
          [dropDownListDataKey]: dropDownListDataValue
        }
      }
    },

    // Reducer Function to Set Form-warning in Patient Module (Patient-Add, Patient-Info, PatientInsuranceInfo)
    // Function accepts name of the input element, its value & node as tab name - which tab has respective input
    // When user has given input data & then to the respective key the same input value or dummy value can be assigned, and if input value is missing then passing empty string which will flag as input error
    // If Patient_Type Input is Self-Pay & Patient has no active insurances then warning should not be applied to insurance field
    // FEEL FREE TO REFACTOR THIS CODE-BLOCK AS THIS WILL TRIGGER ON  VALUE CHANGE - BUT MAKE SURE RETAIN ITS CURRENT FUNCTIONALITY
    setPatientFormWarnings: (state, actions) => {
      const { name, value, node, patientPK } = actions.payload; // Accepted arguments

      let formWarningsData;
      if (patientPK && state.patientFormWarnings[patientPK])
        formWarningsData = state.patientFormWarnings[patientPK].map((item) => ({ ...item })); // Creating a new mutable array from current state
      else
        formWarningsData = state.patientFormWarnings.model.map((item) => ({ ...item }));
      
      // Evoked from Patient Add Tab - Parent element of all the sub tabs
      if (name === "lastName" || name === "last_name") {
        formWarningsData[0] = { ...formWarningsData[0], "Last Name": value };
        
      } else if (name === "firstName" || name === "first_name") {
        formWarningsData[0] = { ...formWarningsData[0], "First Name": value };

      } else if (name === "gender" ) {
        formWarningsData[0] = { ...formWarningsData[0], Gender: value };

      } else if (name === "DOBDate" || name === "date_of_birth") {
        formWarningsData[0] = { ...formWarningsData[0], DOB: value };

      } else if (name === "patientType" || name === "patient_type") { 
        formWarningsData[0] = { ...formWarningsData[0], "Patient Type": value };
          if (value === "Self-Pay Patient") { 
            formWarningsData[2] = { ...formWarningsData[2], "Insurance Details": "Mock value" }; // If Patient type is Self-Pay then passing dummy value to remove the insurance warnings
          } else {
            if (value === 0) formWarningsData[2] = { ...formWarningsData[2], "Insurance Details": "" }; // If value 0 means patient has 0 active insurance policy then passing empty string to set the warning
            if (value > 0) formWarningsData[2] = { ...formWarningsData[2], "Insurance Details": "Insurance Exists" }; // If value 0 means patient has 0 active insurance policy then passing empty string to set the warning
          }

      } else if ( name === "insuranceDetails" ) { // Evoked from Insurance-Info Tab - Child Tab - Updates the warning when a new Insurance Policy is added or removed
        if (formWarningsData[0]["Patient Type"] === "Self-Pay Patient" && value === "") { // If Patient has not active policy but current patient_type is Self-Pay then insurance missing warning not required
            formWarningsData[2] = { ...formWarningsData[2], "Insurance Details": 1 };   
        } else {
          formWarningsData[2] = { ...formWarningsData[2], "Insurance Details": value };
        }
        

      } else if (node === "patientInfo") { // Evoked from Patient-Info Tab (Sub Tab)
        const keyMap = { street1: "Street1", city: "City", state: "State", zip: "Zip", rendering_provider: "Rendering Provider", };
        const key = keyMap[name];
        switch (key) {
          case "Street1":
          case "City":
          case "State":
          case "Rendering Provider":
            formWarningsData[1][key] = value;
            break;
          case "Home Phone": // Phone Num Logic - 11 Or more than 11 Digit integers 
            formWarningsData[1][key] = (value && value.trim() !== "") && value !== "1" && value !== undefined && value.length >= 11 ? value : "";
            break;
          case "Zip":
            formWarningsData[1][key] = value;
            formWarningsData[1]["Invalid Zip"] = (value.length === 5) ? "false" : "";
            break;
          default:
            break;
        }
      }

      return {
        ...state,
        patientFormWarnings: {
          ...state.patientFormWarnings,
          [patientPK || "model"]: formWarningsData
        }
      }
    },


    /**
     * removing patientInfoData values on patientTab removal
     * @param {*} state
     * @param {*} actions
     */
    resetPatientData: (state) => {
      state.patientAddDetails = initialState.patientAddDetails;
      state.patientInfoDetails = initialState.patientInfoDetails;
    },

    /**
     *setting all data to Initial state
     * @param {*} state
     */
    setInitialState: () => initialState,
  },

  /**
   *extra reducer for handling api calls and api call status check
   * @param {*} builder
   */
  extraReducers:{
    /**
     * if api call status is success
     * @param {*} state
     * @param {*} actions
     * actions contain patientPk and called api success data
     */
      [getPatientAddDetails.fulfilled]:(state, actions) => {
      if (actions.payload.type !== "UPDATE") {
        const Index = state.patientInfoData.findIndex(
          (item) => item.patientPK === actions.payload.pk
        );
        if (Index === -1)
          state.patientInfoData.push({
            patientAddVariables: actions.payload.patientAddVariables,
            patientPK: actions.payload.pk,
            patientInfoPK: actions.payload.infopk,
            patientInfoVariables: actions.payload.patientInfoVariables,
          });
      }
      // If practice_location or facility_location is available in the patient api data then...
      // Add an additional key named "service_location_value" in the sate and set respective value like "3||facility" or "3||practice"
      // This is required because in the service list drop-down its showing both practices and facilities so make ir selected appropriately the "service_location_value" should be in this format with pipe
      if (actions.payload.patientInfoVariables?.practice_location) {
        actions.payload.patientInfoVariables.service_location_value = `${actions.payload.patientInfoVariables.practice_location}||practice`
      }
      if (actions.payload.patientInfoVariables?.facility_location) {
        actions.payload.patientInfoVariables.service_location_value = `${actions.payload.patientInfoVariables.facility_location}||facility`
      }
      state.patientInfoDetails = actions.payload.patientInfoVariables;
      state.patientAddDetails = actions.payload.patientAddVariables;
      state.pks.push(actions.payload.pk);
      state.loading = false;
    },

    /**
     *checking api call response is pending
     * @param {*} state
     * @param {*} actions
     */
    [getPatientAddDetails.pending]: (state) => {
      state.loading = true;
    },

    /**
     *checking api call response is rejected or any error
     * @param {*} state
     * @param {*} actions
     */
    [getPatientAddDetails.rejected]: (state) => {
      state.loading = false;
      state.error = "";
    },
    /**
     * storing rendering providerlist on api call success
     * @param {*} state 
     * @param {*} actions 
     */
    [getRenderingProviders.fulfilled]:(state,actions)=>{
      state.renderingProviderList=actions.payload
    },
     /**
      * get rendering provider api call pending
      * @param {*} state 
      * @param {*} actions 
      */
    [getRenderingProviders.pending]: (state) => {
      state.error="";
    },
    
    /**
     *  get rendering provider api call with an error
     * @param {*} state 
     * @param {*} actions 
     */
    [getRenderingProviders.rejected]: (state) => {
      state.error="";
      state.renderingProviderList=[];
    }
  },
});

export const {
  setPatientInfo,
  setInitialState,
  getpatientInfo,
  updatePatientInfo,
  resetPatientData,
  updatePatientOtherInfo,
  setPatientFormWarnings,
  resetPatientDataOnTabClose,
  activatePatient
} = patientInfoSlice.actions;

export default patientInfoSlice.reducer;
